import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";
import Founder from "../../images/Founder.jpg";
import Developer from "../../images/developer.png";
import "slick-carousel/slick/slick.css";
import PAHeader from "components/headers/PAHeader.js";
import Footer from "components/footers/PAFooter.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded-3xl`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;
const TextContainer2 = tw.div`py-4 flex flex-col items-center justify-center`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
const Quote = tw.blockquote`text-center  font-medium text-xl lg:text-2xl xl:text-3xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-[#295dcd]`;
const CustomerTitle = tw.p`font-medium text-sm`;

const QuotesLeft = tw(
  QuotesLeftIcon
)`w-8 h-8 lg:w-10 lg:h-10 text-[#295dcd] absolute top-0 left-0`;
const QuotesRight = tw(
  QuotesRightIcon
)`w-8 h-8 lg:w-10 lg:h-10 text-[#295dcd]  absolute bottom-0 right-0`;




const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default () => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const testimonials = [
    {
      imageSrc: Founder,
      quote:
        "I'm the founder of PA Softwares. We specialize in building top-tier web and mobile applications, prioritizing user satisfaction. My expertise lies in React Native for mobile app development and Python Django for robust backend infrastructure. We leverage services like Firebase, Heroku, and Cloudinary to enhance our applications and deliver exceptional experiences to clients and users",
      customerName: "Narayanan Sathappan",
      customerTitle: "Founder",
    },
    {
      imageSrc: Developer,
      quote:
        "I work as a front-end React developer at PA Softwares, where I leverage my expertise in React, Tailwind CSS, and Next.js to create outstanding web applications. My role involves crafting intuitive and visually appealing user interfaces.",
      customerName: "Ashish Sharma",
      customerTitle: "Front-End Developer",
    },
  ];
  return (
    <>
      <div tw=" bg-[#ffe000] p-2  h-20 ">
   
        <PAHeader />
      </div>
      <Container>
        <Content>
          <HeadingInfoContainer>
            <h1 tw="font-bold text-2xl text-[#295dcd]">Our Team</h1>
            <HeadingTitle>Meet These Fine Folks.</HeadingTitle>
            <HeadingDescription></HeadingDescription>
          </HeadingInfoContainer>

          {testimonials.map((testimonial, index) => (
            <Testimonial key={index}>
              <ImageContainer>
                <img
                  src={testimonial.imageSrc}
                  alt={testimonial.customerName}
                />
              </ImageContainer>
              <TextContainer>
                <QuoteContainer>
                  <QuotesLeft />
                  <Quote>{testimonial.quote}</Quote>
                  <QuotesRight />
                </QuoteContainer>
                <CustomerInfo>
                  <CustomerName>{testimonial.customerName}</CustomerName>
                  <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                </CustomerInfo>
              </TextContainer>
            </Testimonial>
          ))}
        </Content>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </Container>
      <HeadingTitle tw="text-[#295dcd]">Our Vision</HeadingTitle>
   
      <TextContainer2>     <QuoteContainer tw="xl:w-5/12 lg:w-5/12 md:w-5/12 sm:w-11/12 "> <QuotesLeft />  <Quote>
        To be No. 1 Innovative and Creative Software Development Company which
        promotes efficiency and makes business operations easier and process
        driven for our partners and customers using highly reliable and robust
        software solutions  </Quote> <QuotesRight /> </QuoteContainer> </TextContainer2>
      
      <HeadingTitle tw="text-[#295dcd]">Our Mission</HeadingTitle>
      <TextContainer2>     <QuoteContainer tw="xl:w-5/12 lg:w-5/12 md:w-5/12 sm:w-11/12 " > <QuotesLeft />  <Quote>
      Build robust and highly reliable software solutions which will automate
        and ease the business processes, thereby making our partners and
        customers focus more on their core business. Build a software business
        which provide employment to people, provide them a good environment and
        opportunity to work on innovative and creative solutions </Quote> <QuotesRight /> </QuoteContainer> </TextContainer2>

      <Footer />
    </>
  );
};
