import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import Pulse from "../../images/Apps/sc10.png";
import StartupConnect from "../../images/Apps/sc6.png";
import Canopi from "../../images/Scadaf.jpg";
import ProcessTracker from "../../images/ProcessTracker-1.png";
import Kriya from "../../images/Apps/Group 122.png";
import NellaiConnect from "../../images/Apps/s4.png";

const HeaderRow = tw.div`flex justify-center items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;


const TabContent = tw(
  motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(
  motion.a
)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: ${tw`absolute inset-0 flex justify-center items-center`};
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm `;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Projects Completed",
  tabs = {
    Starters: [
      {
        imageSrc: Canopi,
        title: "SCADA for Transformer",
        content:
          "Scada for transformer and Reactor Testing is a app includes features such as corporate login, viewing invoices which are pending to be paid...",

        rating: "4.9",
        reviews: "89",
        url: "/scada",
      },
      {
        imageSrc: ProcessTracker,
        title: "Process Tracker",
        content:
          "Stay on top of your tasks with our process tracking app, making productivity effortless Efficiently manage your workflow and monitor progress .",
        rating: "5.0",
        reviews: "87",
        url: "/process-tracker",
      },

      {
        imageSrc: StartupConnect,
        title: "Startup Connect",
        content:
          "Startupconnect is a networking platform for startups in a coworking place. It includes features like Listing all the startups in a coworking space with name, description.",
        // price: "$5.99",
        rating: "4.6",
        reviews: "12",
        url: "/startup-connect",
      },
      {
        imageSrc: Pulse,
        title: "Pulse",
        content:
          "Pulse is the app developed for a manufacturing company in collaboration with an IoT startup to view the data from various sensors they use in their plant...",

        rating: "4.8",
        reviews: "32",
        url: "/pulse",
      },


      // {
      //   imageSrc: Kriya,
      //   title: "Kriya",
      //   content: "Kriya is the app developed for a healthcare startup to monitor the health data of a pregnant women, provide alerts and suggestions based on the health data",

      //   rating: "4.2",
      //   reviews: "19",
      //   url: "#"
      // },
      // {
      //   imageSrc:NellaiConnect,
      //   title: "Nellai Connect",
      //   content: "News App which shows district news from Tirunelveli, thoothukudi and Nagercoil districts, events happening in these districts with event location, date and time, ...",

      //   rating: "5.0",
      //   reviews: "61",
      //   url: "#"
      // },
    ],
    Main: getRandomCards(),
    Soup: getRandomCards(),
    Desserts: getRandomCards(),
  },
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container id="portfolio">
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header tw="">{heading}</Header>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card
                  className="group"
                  href={card.url}
                  initial="rest"
                  whileHover="hover"
                  animate="rest"
                >
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto",
                        },
                        rest: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton tw="bg-[#ffde00] text-black">
                        Know More
                      </CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
  
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
