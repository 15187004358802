import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/PAHeader.js";

import Footer from "components/footers/PAFooter.js";

import MainFeature1 from "components/features/TwoColWithButton.js";

import Process_1 from "../images/Process Tracking/1. List Of Operations.png";
import Process_2 from "../images/Process Tracking/2. List Of Machines.png";
import Process_3 from "../images/Process Tracking/3. List Of Operators.png";
import Process_4 from "../images/Process Tracking/4. List Of Processes.png";
import Process_5 from "../images/Process Tracking/5. List Of Products.png";
import Process_6 from "../images/Process Tracking/6. List Of Jobs.png";
import Process_7 from "../images/Process Tracking/7. List Of Job Cards.png";
import Process_8 from "../images/Process Tracking/8. Update Operator in Job Card.png";
import Process_9 from "../images/Process Tracking/9. Add Raw Material and Part Code in Job Card.png";
import Process_10 from "../images/Process Tracking/10. Report on List Of Operations Completed.png";
import Process_11 from "../images/Process Tracking/11. Report Detail - View Part Codes Generated from Selected Operation.png";

import PT from "../images/ProcessTracker-1.png";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./customGallery.css";
const Subheading = tw.span`uppercase tracking-wider text-[#ffde00] text-sm`;

const images = [
  {
    original: Process_1,
    thumbnail: Process_1,
  },
  {
    original: Process_2,
    thumbnail: Process_2,
  },
  {
    original: Process_3,
    thumbnail: Process_3,
  },
  {
    original: Process_4,
    thumbnail: Process_4,
  },
  {
    original: Process_5,
    thumbnail: Process_5,
  },
  {
    original: Process_6,
    thumbnail: Process_6,
  },
  {
    original: Process_7,
    thumbnail: Process_7,
  },
  {
    original: Process_8,
    thumbnail: Process_8,
  },
  {
    original: Process_9,
    thumbnail: Process_9,
  },
  {
    original: Process_10,
    thumbnail: Process_10,
  },

  {
    original: Process_11,
    thumbnail: Process_11,
  },
];

export default () => {


  const [currentIndex, setCurrentIndex] = useState(0);
  const customText = [
    "Text for Image 1",
    "Text for Image 2",
    "Text for Image 3",
    
  ];

  const renderCustomItem = (item) => {
    return (
      <>
      
      <div className="image-gallery-item">
      
        <div className="image-gallery-image">
          <img src={item.original} alt={item.originalAlt} />
          <div tw="absolute top-[10px] left-[350px] flex  items-center justify-center bg-[#ffde00] p-5 border rounded-xl   text-[#295dcd] text-3xl">{customText[currentIndex]}</div>
        </div>
        
      </div></>
    );
  };

  const onSlide = (index) => {
    setCurrentIndex(index);
  };









  return (
    <div>
      <div tw=" bg-[#ffe000] p-2  h-20 ">
        <Header />
      </div>
      <AnimationRevealPage>
        <MainFeature1
          subheading={<Subheading>About Process Tracking</Subheading>}
          heading="Stay on top of your tasks with our Process Tracking App."
          buttonRounded={false}
          primaryButtonText="See Portfolio"
          imageSrc={PT}
        />

        <ImageGallery items={images} showPlayButton={false}  renderItem={renderCustomItem}
      onSlide={onSlide} />

        <Footer />
      </AnimationRevealPage>
    </div>
  );
};
