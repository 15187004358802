import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Linkdin from "images/Linkedin.svg";

import logo from "../../images/PA-Logo-bg-white.png";

const Container = tw.div`relative bg-[#ffde00] text-black -mb-8 -mx-8 px-8 py-4 `;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;

const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-700`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full text-gray-900  transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;



export default () => {
  return (
    <Container>
      <Content>
       
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText tw="text-black">PA Softwares</LogoText>
          </LogoContainer>
          <CopywrightNotice>&copy; 2023 PA Softwares All Rights Reserved.</CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink target="_blank" href="https://www.linkedin.com/company/pa-softwares/">
          <img src={Linkdin} alt="linkdin-ICon" />
            </SocialLink>
          
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
     
    </Container>
  );
};
